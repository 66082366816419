@tailwind base;
@tailwind components;
@tailwind utilities;

.fc-event {
  background-color: #d1fae5;
  border: none;
  border-radius: 26px;
  padding: 0.1rem 0.3rem;
  margin-top: 0.3rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  user-select: none;
}

.fc-toolbar-chunk {
  display: flex;
}

.fc-toolbar-title {
  color: #ffffff;
  font-size: 1.5rem !important;
}
.fc-timeline-slot-frame,
.fc-datagrid-cell-main {
  color: #667280;
}

.fc-header-toolbar {
  background: #f3f4f6;
  padding: 0.5rem 0.5rem;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0rem;
}

.fc-timeline-header-row {
  background: #f3f4f6;
}

.fc-timeline-slot-frame {
  height: 50px !important;
}
.fc-scrollgrid-section th {
  background-color: #f3f4f6;
}

.fc-datagrid-header {
  border: 2px solid #f3f4f6;
}
.fc th {
  height: 50px !important;
}

.fc {
  max-height: calc(100vh - 65px);
}

.fc-event-time {
  color: #ca8a04;
}

.fc-theme-standard td {
  vertical-align: middle;
}

.fc-license-message {
  display: none;
}

.fc .fc-button-primary{
  color: #000 !important;
  background-color: #fff !important;
  border-color: #375570 !important;
}

.ganttfilter {
margin-left: 1rem;
}

.fc .fc-today-button {
  color: #000 !important;
  background-color: #ffffff !important;
  border-color: #375570 !important;
}

.fc .fc-button-active {
  background-color: #375570 !important;
  color: #ffffff !important;
  border-color: #375570!important;
}

.fc-header-toolbar {
  background-color: #6888A5 !important;
}

.fc-resource-timeline-divider {
  width: 0.5em !important;
}

.fc .fc-timeline-now-indicator-line {
  border-width: 0px 0px 0px 3px;
  bottom: 0px;
  margin: 0px -2px;
}

.fc .fc-timeline-lane {
  background: linear-gradient(to top, #eee 0%, #ccc 50%, #eee 100%);
  border-color: #b9bfc7;
}

.fc .fc-timeline-slot-lane {
  border: 1px solid #b9bfc7;
}

.fc .fc-bg-event {
  background-color: white;
  opacity: 65%;
  border-radius: 0;
  padding: 0;
  margin: 0;
  box-shadow: none;
}

.fc-timeline-lane-frame {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.fc-res-group {
  font-weight: bold !important;
}

.fc-datagrid-cell-frame {
  background-color: #6888A5;
  color:#ffffff;
}

.fc-datagrid-cell-frame span {
  color:#ffffff;
  font-weight: bold;
}

.fc-datagrid-body .fc-scrollgrid-sync-table {
  background: #375570;
}

